<template>
  <div v-if="selectedStone" class="order-summary px-5 py-5">
    <div class="order-summary-details">
      <span class="d-inline-block font-weight-bold gray--text text--darken-2">{{ fullName }}</span>
      <div class="py-4">
        <div class="d-flex justify-space-between">
        <span class="d-flex align-start text-body-2 gray--text text--darken-2">
          <span class="d-inline-block mr-1">
            {{ selectedStone.displayName }} <br />
            {{ $t("createOrder.visualization.orderSummary.textAfterStoneDisplayName") }}:
          </span>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ $t("createOrder.visualization.orderSummary.stoneTooltipText") }}</span>
          </v-tooltip>
        </span>
          <span class="text-subtitle-2 font-weight-semibold gray--text text--darken-3">
          {{ formatDecimal(selectedStone.pricePerSquareFoot * this.sqft) }}
        </span>
        </div>

        <div v-for="cutout in selectedCutouts" :key="cutout.id" class="mt-4 d-flex justify-space-between">
        <span class="d-flex align-start text-body-2 gray--text text--darken-2">
          <span class="d-inline-block mr-1">{{ cutout.displayName }}:</span>
        </span>
          <span class="text-subtitle-2 font-weight-semibold gray--text text--darken-3">
          {{ formatDecimal(cutout.pricePerCutout * cutout.quantity) }}
        </span>
        </div>

        <div v-if="hasOldCountertopRemoval" class="mt-4 d-flex justify-space-between">
        <span class="d-flex align-start text-body-2 gray--text text--darken-2">
          <span class="d-inline-block mr-1">{{ $t("createOrder.kitchenDetails.form.hasOldCountertopRemoval.label")
            }}:</span>
        </span>
          <span class="text-subtitle-2 font-weight-semibold gray--text text--darken-3">
          {{ oldCountertopRemovalFee }}
        </span>
        </div>
      </div>
      <v-divider class="mb-4" />
    </div>
    <div class="pb-md-0 pb-6">
      <div class="d-flex justify-space-between">
      <span class="font-weight-semibold gray--text text--darken-2">
        {{ $t("createOrder.visualization.orderSummary.total") }}:
      </span>
        <span :class="['font-weight-semibold gray--text text--darken-3', { 'text-decoration-line-through': Boolean(appliedCoupon) }]">
        {{ totalAmount }}
      </span>
      </div>
      <div v-if="appliedCoupon" class="d-flex justify-end mt-1">
      <span class="font-weight-semibold primary--text">
        {{ totalAmountAfterDiscountText }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  calculateTotalAmount,
  usdCurrencyFormatter,
  getOldCountertopRemovalFee,
  getTotalAmountAfterDiscountText
} from "@/utils/payment";

export default {
  methods: {
    formatDecimal: usdCurrencyFormatter.format
  },
  computed: {
    ...mapState("Customer", {
      fullName: state => state.fullName
    }),
    ...mapState("KitchenLayout", {
      sqft: state => state.sqft
    }),
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone
    }),
    ...mapState("KitchenDetails", {
      hasOldCountertopRemoval: state => state.hasOldCountertopRemoval,
      selectedCutouts: state => state.selectedCutouts
    }),
    ...mapState("Deposit", {
      appliedCoupon: state => state.appliedCoupon
    }),
    oldCountertopRemovalFee() {
      return this.formatDecimal(getOldCountertopRemovalFee());
    },
    totalAmount() {
      const total = calculateTotalAmount(this.selectedStone, this.sqft, this.hasOldCountertopRemoval, this.selectedCutouts);
      return this.formatDecimal(total);
    },
    totalAmountAfterDiscountText() {
      const totalAmount = calculateTotalAmount(this.selectedStone, this.sqft, this.hasOldCountertopRemoval, this.selectedCutouts);
      return getTotalAmountAfterDiscountText(totalAmount, this.appliedCoupon);
    }
  }
};
</script>
