<template>
  <div class="app-layout">
    <header class="app-header">
      <v-row>
        <v-col class="d-flex align-center">
          <img src="@/assets/images/stonely-logo.svg" alt="Stonely logo" />
        </v-col>
      </v-row>
    </header>
    <v-main>
      <v-row>
        <v-col lg="9" md="8" sm="12">
          <div class="py-md-4 py-3 text-center">
            <h1 class="text-h6 text-md-h5 font-weight-regular gray--text text--darken-3">
              {{ $t("createOrder.layouts.designCountertop.title") }}
            </h1>
          </div>
          <v-stepper v-model="currentStepIndex" class="elevation-0" alt-labels>
            <v-stepper-header>
              <template v-for="(step, index) in visibleSteps">
                <v-stepper-step
                  :complete="step.completed"
                  :key="`step-${index}`"
                  :step="index + 1"
                >
                  {{ step.label }}
                </v-stepper-step>
                <v-divider
                  v-if="!isLastStep(index)"
                  :key="`divider-${index}`"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
          <router-view />
        </v-col>
        <v-col lg="3" md="3" class="d-md-flex d-none">
          <div class="app-right-pane">
            <s-contact-details v-if="isVisualizationStepActive && !customerInfoFilled" />
            <s-order-summary v-if="isVisualizationStepActive && customerInfoFilled && selectedStone" />
            <s-stone-details v-if="isVisualizationStepActive && customerInfoFilled && selectedStone" />
          </div>
        </v-col>
      </v-row>

      <div v-if="isVisualizationStepActive && customerInfoFilled && selectedStone" @click="toggleMobileOrderSummary"
           :class="['mobile-order-summary', { 'expanded': !mobileOrderSummaryCollapsed }]">
        <div role="button" class="clickable-area">
          <svg xmlns="http://www.w3.org/2000/svg" width="86" height="6" viewBox="0 0 86 6" fill="none">
            <path d="M3 3H83" stroke="#BDBDBD" stroke-width="5" stroke-linecap="round" />
          </svg>
        </div>
        <s-order-summary />
      </div>

      <v-overlay @click="toggleMobileOrderSummary" :z-index="999" :value="!mobileOrderSummaryCollapsed" />
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ContactDetails from "@/views/create-order/visualization/ContactDetails.vue";
import StoneDetails from "@/components/create-order/StoneDetails.vue";

export default {
  components: {
    "s-stone-details": StoneDetails,
    "s-contact-details": ContactDetails
  },
  data() {
    return {
      mobileOrderSummaryCollapsed: true,
      currentStepIndex: 1
    };
  },
  computed: {
    ...mapState("Steps", {
      steps: (state) => state.designSteps
    }),
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone
    }),
    ...mapGetters("Customer", ["customerInfoFilled"]),
    isVisualizationStepActive() {
      return this.$route.name === "visualization";
    },
    visibleSteps() {
      return this.steps.filter(step => step.showInStepper === true);
    }
  },
  mounted() {
    this.setCurrentStepFromRoute();
  },
  updated() {
    const currentPath = this.$route.path;
    const step = this.steps.find((step) => step.path === currentPath);
    this.currentStepIndex = step.stepperIndex;
  },
  methods: {
    toggleMobileOrderSummary() {
      this.mobileOrderSummaryCollapsed = !this.mobileOrderSummaryCollapsed;
    },
    isLastStep(index) {
      return index === this.visibleSteps.length - 1;
    },
    setCurrentStepFromRoute() {
      const currentPath = this.$route.path;
      const stepIndex = this.steps.findIndex((step) => step.path === currentPath);

      const firstAvailableStep = this.findFirstAvailableStep(stepIndex);
      this.currentStepIndex = firstAvailableStep.stepperIndex;
      this.$router.push(firstAvailableStep.path).catch(() => {});
    },
    findFirstAvailableStep(startIndex) {
      for (let i = startIndex; i >= 1; i--) {
        const step = this.steps[i];
        const prevStep = this.steps[i - 1];
        if (prevStep.completed) {
          return step;
        }
      }
      return this.steps[0];
    }
  }
};
</script>
