import colors from "vuetify/lib/util/colors";

export default {
  primary: "#da1f47",
  gray: {
    base: "#9e9e9e",
    lighten5: "#fafafa",
    lighten4: "#f5f5f5",
    lighten3: "#eeeeee",
    lighten2: "#b0bec5",
    lighten1: "#bdbdbd",
    darken1: "#757575",
    darken2: "#616161",
    darken3: "#424242",
    darken4: "#212121"
  },
  blue: {
    base: "#1976d2",
    lighten5: "#e3f2Fd"
  },
  'blue-grey': colors.blueGrey
};
